
<template>
  <div id="ridesTable">
    <!--     <v-card :class="this.$headerColor">
 -->
    <div v-if="rideLeader === 'all'">
      <v-card :color="this.$headerColor" flat>
        <v-row justify="center">
          <v-col cols="2">
            <v-text-field v-model="search" label="Search in rides" prepend-icon="mdi-magnify" width="40"
              single-line></v-text-field>
          </v-col>
          <!-- ENABLE TO BROWSSE OTHER YEARS
            
            <v-col cols="2">
            <v-text-field v-model="rideYear" value="rideYear" type="number" prepend-icon="mdi-calendar"
              @click="changeYear(mode)" cols="2">
            </v-text-field>

          </v-col>
          <v-btn class="btn" color="green lighten-2" light @click="changeMode(mode)">
            Display {{ mode === 'YEARLY' ? 'WEEKLY' : 'YEARLY' }} Rides
          </v-btn>
 --> </v-row>

      </v-card>
    </div>
    <v-card :color="this.$bgColor" :height="height" :class="rideLeader === 'all' ? 'view' : 'expanded-rides'"
      class="view">
      <v-card class="table-container" :color="this.$bgColor">
        <v-data-table disable-pagination :v-model="rideList" l:height="rideLeader === 'all' ? 650 : -1" class="flex-table"
          multi-sort fixed-header :hide-default-header="rideLeader === 'all' ? false : false" hide-default-footer dsdense
          :class="this.$bgColor" :headers="headers" :items="rideList" :search="search">
          <!--       <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="h in headers" :class="headerColor" :key="h.rideLeader">
              <span>{{ h.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
 -->

          <template v-slot:item.edit="{ item }">
            <v-btn :color="btnColor" small @click="teamUpEdit(item.id)">Edit</v-btn>

          </template>
          <template v-slot:item.displayStatus="{ item }">
            <v-chip dark small :color="getStatusColor(item['custom'])">
              {{ item.displayStatus }}
            </v-chip>
            <!-- 
                     <v-col>
            <v-chip dark small :color="getStatusColor(item['custom'])">
              <v-icon>mdi-bicycle</v-icon>
            </v-chip>
          </v-col>
 -->
          </template>

          <template v-slot:item.pace="{ item }">
            <v-chip dark small :color="displayPace(item)">
              {{ item.pace }}
            </v-chip>
          </template>

          <template v-slot:item.rrule="{ item }">
            {{ formatRrule(item.rrule) }}
          </template>
          -->
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>
<script>
// import axios from "axios";
//import Tip from "../components/Tip";
import mixins from "../components/mixins";
import EventBus from "../event-bus";
import moment from "moment";
export default {
  name: "RidesTable",
  components: {
    //    Tip,
  },
  mixins: [mixins],
  props: {
    rideLeader: {
      type: String,
      default: "all",
    },

    rideStatus: {
      type: String,
      default: "all",
    },

    hideHeader: {
      type: String,
      default: "true",
    },
    height: {
      type: String,
      default: "-1",
    },
  },
  data() {
    return {
      mode: "YEARLY",
      newHeaders: [],
      btnColor: "",
      size: 0,

      year: "",
      rideList: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      hidden: null,
      search: "",
      rideYear: "",
      //      customHeaders: [],
      headers: [
        {
          text: "Ride Date",
          value: "Date",
          sort: this.timeSort,
          align: "left",
          width: "174",
        },
/*
        {
          text: "End Date",
          value: "Date",
          sort: this.timeSort,
          align: "left",
          width: "174",
        },
         { text: "Repeat ", value: "rrule", width: "40" },

 */        {
          text: "Ride Leader",
          value: "who",
          width: "140",
        },

        /*         { text: "", value: "tz", sortable: false },
         */
        {
          text: "Status",
          value: "displayStatus",
          align: "center",
          width: "1",
        },
        { text: "Pace", value: "pace", align: "center", width: "1" },
        { text: "Edit", value: "edit", width: "1" },
        { text: "Ride Name", value: "title", width: "385", align: "left" },
      ],
    };
  },

  async mounted() {
    //EventBus.$emit("wait", true);
    await this.setConfig().then((ret) => {
      this.rideYear = this.getYear("current");
      if (ret === "login") this.$router.push({ path: "/" });
    });

    //this.changeYear();
    this.btnColor = this.$buttonColor;
    this.rideList = await this.getRides(this.rideLeader, this.rideStatus, "", this.rideYear + "-1-1", this.rideYear + "-12-31");
    this.rideList.sort((a, b) => moment(a.start_dt) - moment(b.start_dt));
    EventBus.$emit("wait", false);
    return (this.rideList);

    // Ignore week<ly rides 
  },
  /*this.rideList = this.rideList.sort((a, b) => {
    var t1 = moment(a.Date, "ddd MM/DD/YYY h:mm").unix();
    var t2 = moment(b.Date, "ddd MM/DD/YYY h:mm").unix();
    return t1 - t2;
  });
  EventBus.$emit("placeHolder", true);
  EventBus.$emit("wait", false);
},

//    this.onResize(this.rideLeader);
*/
  methods: {


    formatRrule(rule) {
      if (rule === "") return "";
      var repeat = "";
      var freq = rule.split("=")[1];
      if (rule.search("UNTIL") > -1) {
        var untilDate = rule.split("UNTIL=")[1].split("T")[0];
        if (untilDate !== undefined)
          repeat = " " + moment.utc(untilDate).format("MM/DD/YYYY");
      }
      return (freq + repeat)
    },

/* 
    pageSize(item) {
      console.log(this.pp(item.page));
    },
 */    displayStatusValue(item) {
      //      var vals = this.$store.state.statusValues;
      var displayCustom = this.copy(item.displayCustom);
      for (var i = 0; i < displayCustom.length; ++i) {
        if (this.fuzzyMatch("status", displayCustom[i].name))
          return displayCustom[i].value;
      }
      return "None";
    },
    async changeYear(force = "force") {
      this.rideList = await this.getRides(this.rideLeader, this.rideStatus,
        force,
        this.rideYear + "-1-1", this.rideYear + "-12-31");

      this.btnColor = this.$buttonColor;
    },
    async changeMode(mode) {
      this.mode = mode === 'YEARLY' ? 'WEEKLY' : 'YEARLY';
      this.rideList = await this.getRides(this.rideLeader, this.rideStatus, this.mode, this.rideYear + "-1-1", this.rideYear + "-12-31");
      /*
            if (mode === "YEARLY") {
              //  this.headers.push(endDate);
              var index = this.headers.findIndex((item) => item.value === "Date");
              if (index !== -1)
                this.headers.splice(index + 1, 0, endDate)
            }
            else {
              index = this.headers.findIndex((item) => item.value === "end_dt");
              if (index !== -1)
                this.headers.splice(index, 1)
            }
      */
    },

    /*       this.rideList = this.rideList.sort((a, b) => {
       var t1 = moment(a.Date, "ddd MM/DD/YYY h:mm").unix();
       var t2 = moment(b.Date, "ddd MM/DD/YYY h:mm").unix();
       return t1 - t2;
     });
*/

    displayPace(item) {
      const paceID = this.getPaceID("pace");
      if (paceID in item.custom === true) {
        var pace = this.displayCustom("ride_pace_", item.custom);
        return pace["color"];
      }
    },

    timeSort(a, b) {
      //      var t1 = new Date(a).getTime();
      //      var t2 = new Date(b).getTime();
      //var t1 = moment(a, "ddd M/DD/YYY h:mm").unix();
      // var t2 = moment(b, "ddd M/DD/YYY h:mm").unix();
      var t1 = moment(a, "ddd M/DD/YYY h:mm");
      var t2 = moment(b, "ddd M/DD/YYY h:mm");
      //      t1 = t1.getTime();
      //      t2 = t2.getTime();
      return t1 < t2 ? -1 : 1;
    },

    /*
    dateSort(a, b) {
      var t1 = new Date.parse(a);
      var t2 = new Date.parse(b);
      t1 = t1.getTime();
      t2 = t2.getTime();
      var x = t1 > t2 ? 1 : -1;
      return x;
    },
    */
    /*
    onResize(rideLeader) {
      // height + padding + borders
      // var height = (this.$refs.scriptSheet! as any).$el.clientHeight);
      //      var height = this.$refs.clientHeight;
      if (rideLeader === "all") {
        this.windowSize = {
          x: window.innerWidth,
          y: window.innerHeight,
        };
      } else {
        this.windowSize = {
          x: window.innerWidth,
          y: 20,
        };
      }
    },
    */
    teamUpEdit(rideID) {
      var url = "https://teamup.com/" + this.$calendar + "/events/" + rideID;
      const win = window.open(url, "edit");
      if (win !== null) win.location = url;
    },
  },
};

/*     async init(rideLeader, rideStatus) {
  //this.setConfig();
},
*/
</script>
<style>
.btn {
  margin-top: 20px;
}

.singleView {
  max-height: 3rem;
}

.expanded-rides {
  width: 920px;
}

.tooltip {
  padding-left: 100px;
}
</style>
